@import '../../styles/customMediaQueries.css';

.root {
}

.pageRoot {
  padding-bottom: 90px;

  @media (--viewportMedium) {
    padding-bottom: 0;
  }
}

.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--colorFail);
  margin: 24px;
}

.sectionHero {
  position: relative;
  margin: 0 0 36px 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.heroHeight {
  @media (--viewportMedium) {
    height: calc(0.41 * 100vw);
    max-height: 50vh;
    overflow-y: hidden;
  }
}

.imageWrapperForSectionHero {
  composes: heroHeight;
  background-color: var(--colorGrey100); /* Loading BG color */

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;
}

.actionBarWithCTAEnabled {
  display: inline-flex;
  flex-wrap: wrap;
  padding: 0 8px;
}

.actionBar {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  color: var(--colorGrey100);
  background-color: var(--colorGrey700);
  z-index: 1; /* bring on top of mobile image */
  padding: 0 24px;

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;

  border-radius: var(--borderRadiusMedium);
  padding-bottom: 0 !important;
  margin-bottom: 10px !important;
  border-bottom: none;
}

.actionBarForProductLayout {
  margin: 0 0 24px 0;
  width: 100%;
}

.actionBarContainerForHeroLayout {
  position: absolute;
  top: 13px;
  left: 13px;
  width: calc(100% - 26px);
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.actionBarForHeroLayout {
  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;
  border-radius: var(--borderRadiusMedium);
}

.actionBarCTA {
  composes: buttonSmall from global;
  display: inline-block;
  width: 100%;
  margin: 10px 0px 20px 0px;

  &:hover,
  &:focus {
    background-color: var(--marketplaceColor);
    border-color: var(--marketplaceColor);
    color: var(--colorWhite);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    width: auto;
    margin: 20px 0 20px 15px;
  }
}

.linkContainer {
  display: inline-flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0 8px;
}

.ownListingText {
  composes: h5 from global;
  padding: 12px 24px;
  margin: 0;
  display: flex;
  align-items: center;
}

.ownListingTextPendingApproval {
  color: var(--colorAttention);
}

.closedListingText {
  composes: h4 from global;
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.addPayoutDetails,
.editListingLink {
  composes: h5 from global;
  flex-shrink: 0;
  margin: 0;
  padding: 12px 24px;
  color: var(--colorGrey100);

  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--colorWhite);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin: 0;
  }

  &.CTAEnabled {
    margin: 24px 0 0 0;
  }
}

.missingPayoutDetailsText {
  color: var(--colorAttention);
}

.payoutDetailsWarning {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.missingPayoutDetailsText {
  color: var(--colorAttention);
}

.payoutDetailsWarning {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.editIcon {
  margin: -4px 7px 0 0;
}

.rootForImage {
  composes: heroHeight;
  width: 100%;
  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    object-fit: cover;
    border-radius: var(--borderRadius);

    &:hover {
      transform: scale(1.005);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.viewPhotos {
  composes: buttonSmall from global;

  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 24px;
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.contentWrapperForHeroLayout {
  @media (--viewportMedium) {
    width: 100%;
    max-width: var(--contentMaxWidth);
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    position: relative;
  }

  @media (--viewportLarge) {
    max-width: calc(var(--contentMaxWidth) + 72px);
    padding: 0 36px;
    margin: 0 auto 117px;
  }

  @media (--viewportXLarge) {
    max-width: var(--contentMaxWidth);
    padding: 0;
  }
}

.mainColumnForProductLayout {
  @media (--viewportMedium) {
  }
  @media (--viewportLarge) {
    /* contentContainer's full width - (orderColumn + paddings + margin) */
    max-width: calc(100% - 400px);
    flex-basis: calc(100% - 400px);
    padding-right: 48px;
    flex-shrink: 0;
    flex-grow: 1;
  }
  & .orderPanelTitle {
    font-size: 24px;
    padding: 0;
    margin: 0 0 10px 0;
    line-height: 130%;
    color: var(--colorGrey900);
    font-weight: var(--fontWeightBold);
  }
}

.orderColumnForProductLayout {
  align-self: flex-start;
  @media (--viewportMedium) {
  }
  @media (--viewportLarge) {
    display: block;
    flex-basis: 400px;
    flex-shrink: 0;
    position: sticky;
    top: 120px;
  }
  & .priceContainer {
    box-shadow: var(--boxShadow);
    padding: 24px;
    border-radius: 10px;
    border: solid 1px var(--colorGrey100);
    background-color: var(--colorGrey50);
    margin-bottom: 24px;
    display: none;
    @media (--viewportLarge) {
      display: block;
    }
  }
}

.price {
  composes: h3 from global;
  font-size: 24px;
  font-weight: var(--fontWeightBold);

  color: var(--marketplaceColor);
  margin: 0 0 0 24px;

  @media (--viewportMedium) {
    margin: 0;
    padding: 0;
  }
}

.perUnit {
  /* Font */
  composes: h5 from global;
  font-weight: var(--fontWeightRegular);

  color: var(--colorGrey500);
  padding: 0;

  margin-top: 0;
  margin-bottom: 0;
  margin-left: 5px;
}

.contentWrapperForProductLayout {
  @media (--viewportMedium) {
    width: 100%;
  }

  @media (--viewportLarge) {
    display: flex;
    flex-direction: row;
  }
}

.mainColumnForHeroLayout {
  flex-basis: 100%;
  margin-bottom: 23px;

  @media (--viewportMedium) {
    margin-top: 50px;
    margin-bottom: 51px;
    flex-shrink: 0;
  }

  @media (--viewportLarge) {
    margin-top: 80px;
    margin-bottom: 0px;
    /* contentContainer's full width - (orderColumn + two paddings + margin + border) */
    max-width: calc(100% - 433px);
    flex-basis: calc(100% - 433px);
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.orderColumnForHeroLayout {
  @media (--viewportMedium) {
  }
  @media (--viewportLarge) {
    display: block;
    margin-top: 80px;
    margin-left: 60px;
    border-left: 1px solid var(--colorGrey100);
    padding-left: 60px;
    flex-basis: 400px;
    flex-shrink: 0;
  }
}

.orderPanel {
  /* Note: panel height might be too much on small viewport - hence min-height */
  @media (--viewportLarge) and (min-height: 950px) {
    position: sticky;
    top: 108px;
  }
}

.productOrderPanel {
  @media (--viewportLarge) {
    display: block;
    box-shadow: var(--boxShadow);
    padding: 24px;
    border-radius: 10px;
    border: solid 1px var(--colorGrey100);
    background-color: var(--colorGrey50);
  }
  /* Note: panel height might be too much on small viewport - hence min-height */
  @media (--viewportLarge) and (min-height: 950px) {
    position: sticky;
    top: 108px;
  }
}

.orderPanelTitle {
  /* Font */
  font-weight: var(--fontWeightBold);
  font-size: 18px;
  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

.sectionAvatar {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -31px;

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Flexbox would give too much width by default. */
  width: 60px;

  @media (--viewportMedium) {
    position: absolute;
    top: -49px;
    margin-left: 0;
    margin-top: 0;
  }
}

.avatarMobile {
  display: flex;

  @media (--viewportMedium) {
    display: none; /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  display: none; /* Hide the large avatar from the smaller screens */

  @media (--viewportMedium) {
    display: flex;
  }
}

.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
  }
}

.desktopPriceValue {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 1px;
  }
}

.desktopPerUnit {
  /* Font */
  composes: h5 from global;
  color: var(--colorGrey700);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.heading {
  margin-bottom: 33px;
  padding: 0 24px;

  @media (--viewportMedium) {
    margin-bottom: 0;
    padding: 0;
  }
}

.title {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--colorGrey700);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 2px;
  }
}

.author {
  width: 100%;
  composes: h5 from global;

  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--colorGrey700);
  }
}

.authorNameLink {
  composes: h4 from global;
  color: var(--colorGrey800);
  font-size: 16px;
}

.contactWrapper {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.separator {
  margin: 0 6px;
}

.contactLink {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  margin: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    line-height: 16px;
  }
}

.sectionText {
  padding: 0;
}

.sectionHeading,
.sectionHeadingWithExtraMargin {
  /* Font for sections titles */
  composes: h4 from global;
  color: var(--colorGrey800);
  padding: 0;
  font-weight: var(--fontWeightBold);

  margin-top: 0;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 8px;
  }
}

.sectionHeadingWithExtraMargin {
  @media (--viewportMedium) {
    margin-bottom: 16px;
  }
}

.text {
  font-weight: var(--fontWeightRegular);
  padding: 1px 0 5px 0;
  margin: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    padding: 3px 0 5px 0;
    margin: 0;
  }
}

.ingress {
  composes: p from global;
  margin: 0;
  padding: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.sectionDetails,
.sectionMultiEnum {
  padding: 0px;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    margin-bottom: 40px;
  }
}

.sectionMap {
  padding: 0px;
  border-top: solid 1px var(--colorGrey100);
  padding-top: 24px;
  margin-top: 24px;
}

.sectionReviews,
.sectionAuthor {
  padding: 0px;
  border-top: solid 1px var(--colorGrey100);
  padding-top: 24px;
  margin-top: 24px;
}
.sectionReviews {
  & :global(.slick-slider) {
    /* width: 100%; */
    margin: 0px -10px;
  }
  & :global(.slick-track) {
    display: flex;
    margin: 0;
  }
  & :global(.slick-slide) {
    padding: 0px 10px;
    height: auto;
    & > div {
      display: flex;
      height: 100%;
    }
  }
}

.details {
  margin: 0;
}

.detailsRow {
  display: inline-flex;
  width: 100%;
  line-height: 130%;
  list-style: none;
  font-weight: var(--fontWeightRegular);
  padding: 0;
  &:not(:last-child) {
    border-bottom: 1px solid var(--colorGrey100);
  }
  & > span {
    padding: 10px 0;
  }
}

.detailLabel {
  font-weight: var(--fontWeightSemiBold);
  letter-spacing: -0.09px;
}

.inquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.inquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.inquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--colorWhite);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: 250px;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;

  /* Static map: dimensions are 640px */
  max-width: 100%;
  max-height: 640px;
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    height: 75vh;
  }

  @media (--viewportLarge) {
    height: 417px;
    margin-bottom: 7px;
  }
}

.longWord {
  word-break: break-word;
  hyphens: auto;
}

.productGallery {
  margin: 0 0 24px 0;

  @media (--viewportLarge) {
    margin: 0 0 40px 0;
  }
  & :global(.image-gallery-slide-wrapper) {
    border-radius: 10px;
    overflow: hidden;
  }
  & :global(.image-gallery-thumbnails) {
    padding-top: 15px;
  }
  & :global(.image-gallery-thumbnail) {
    width: 60px;
    height: 60px;
    overflow: hidden;
    cursor: pointer;
    border-width: 2px !important;
    padding: 2px;
    display: inline-flex;
    align-items: stretch;

    & img {
      transform: scale(1);
      transition: var(--transitionStyle);
      max-width: 100%;
      max-height: 100%;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &:hover {
      & img {
        transform: scale(1.08);
        transition: var(--transitionStyle);
      }
    }
  }
  & :global(.image-gallery-thumbnails-container) {
    text-align: center !important;
  }
}

.productDesktopHeading {
  display: none;
  @media (--viewportLarge) {
    display: block;
  }
}

.productSideContent {
}

.productBookingPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 24px;

    /* The calendar needs 312px */
    flex-basis: 400px;
    flex-shrink: 0;
  }
}

.contentWidth {
  position: relative;
  z-index: 1;
  max-width: 1290px;
  width: 100%;
  margin: 0px auto;
  padding: 24px 24px 40px 24px;
  @media (--viewportLargeWithPaddings) {
    padding: 50px 24px;
  }
}
.popularMoorage {
  width: 100%;
  display: block;
  background-color: var(--colorWhite);
  position: relative;
  z-index: 0;
  overflow-x: hidden;
  & .contentWidth {
    padding: 0 24px;
    display: block;

    & :global(.slick-track) {
      margin: 0;
    }
    & :global(.slick-list) {
      margin: 0 -10px;
    }
    & :global(.slick-slide) {
      padding: 0px 10px;

      & > div {
        display: flex;
        & > a {
          width: 100%;
        }
      }
    }
  }
  & :global(.slick-slider .slick-list .slick-slide .slick-list) {
    margin: 0px;
  }
  & :global(.slick-slide .slick-slider .slick-list .slick-slide) {
    padding: 0px;
  }
  & .card {
    position: relative;
    padding-bottom: 100%;
    display: flex !important;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 6px;
    overflow: hidden;
    & .cardDetails {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      text-align: center;
      padding: 20px;
      & > h3 {
        font-size: 20px;
        font-weight: 600;
        line-height: 38px;
        letter-spacing: 0em;
        color: var(--colorWhite);
        text-align: center;
        padding: 0;
        margin: 0;
      }
      & .button {
        width: 100%;
        margin-top: 10px;
        transition: var(--transitionStyle);
      }
    }
  }
}

.sectionHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  & .leftSec {
    & h2 {
      font-weight: 600;
      letter-spacing: 0em;
      color: var(--colorBlack);
      padding: 0;
      margin: 0;
    }
    & p {
      font-size: 16px;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: 0em;
      color: var(--textColor);
      margin: 0px;
      padding: 0;
    }
  }
  & .rightSec {
    & > a {
      display: inline-block;
      color: var(--marketplaceColor);
      text-decoration: underline;
      transition: var(--transitionStyle);
      &:hover {
        transition: var(--transitionStyle);
        color: var(--marketplaceColorDark);
        & > svg {
          & > path {
            stroke: var(--colorWhite);
          }
        }
      }
      & > svg {
        margin-left: 12px;
        fill: none;
      }
    }
  }
}

.listingPageWrapper {
  padding-bottom: 50px;
  & .contentWidth {
    & > * {
      border-bottom: solid 1px var(--colorGrey100);
      padding-bottom: 32px;
      margin-bottom: 32px;
      &:last-child {
        margin-bottom: 0;
      }
      &:first-child {
        margin-bottom: 0;
        border-bottom: none;
      }
    }
  }
}

.listingHead {
  margin: 0 0 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px var(--colorGrey100);
  padding-bottom: 24px;
  margin-bottom: 24px;
  & .leftInfo {
    flex-grow: 1;
  }
  & .author {
    width: auto;
  }
  & .orderPanelTitle {
    margin-bottom: 4px;
  }
  & .otherinfo {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    & > span {
      font-size: 15px;
      margin-right: 10px;
      text-transform: capitalize;
      & > strong {
        color: var(--colorBlack);
      }
      &.linkSeparator {
        width: 5px;
        height: 5px;
        border-radius: 10px;
        background-color: var(--colorGrey300);
        display: inline-block;
      }
    }
  }
}

.moreInformation {
  & .infoBlock {
    display: flex;
    align-items: center;
    & > span {
      padding: 6px 0;
      &:first-child {
        width: calc(100% / 2);
        font-weight: var(--fontWeightSemiBold);
        color: var(--colorGrey800);
        @media (--viewportSmall) {
          width: calc(110% / 3);
        }
      }
    }
  }
  & .description {
    margin-top: 24px;
    padding-top: 24px;
    border-top: solid 1px var(--colorGrey100);
    & > p {
      padding: 0;
      margin-top: 0;
      margin-bottom: 16px;
      word-wrap: break-word;
      &:last-child {
        margin: 0;
      }
    }
  }
}
.fullWidthContent {
  position: relative;
  z-index: 0;
  & .contentWidth {
    padding-top: 0;
    & .sectionReviews {
      border-top: none;
      margin-top: 0;
      padding-top: 0;
    }
  }
}

.priceSuffix {
  font-size: 14px;
  color: #949494;
}

.imageContainer {
  position: relative;
}

.badge {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #333333;
  color: #fff;
  padding: 2px 6px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 12px;
  z-index: 10;

  & svg {
    height: 20px;
    width: 20px;
  }
}